import { useCallback, useMemo, useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { MdContentCopy, MdCheck } from 'react-icons/md';
import { AsYouType } from 'libphonenumber-js';
import { useDebounce } from '@kiper/hooks';
import { byCountryCode } from './countries';
import * as S from './styles';

const CustomInput = ({
  name,
  label,
  error,
  icon: Icon,
  onChange,
  value,
  pattern,
  readOnly,
  clipboardOptions,
  isPhoneNumber,
  debounceDelay,
  clearField,
  size,
  variant,
  primaryColor,
  ...props
}) => {
  const debounce = useDebounce({ delay: debounceDelay });

  const [isCopied, setIsCopied] = useState(false);

  const getLocationLanguage = language => {
    if (!language) return null;
    const [, countryCode] = language.split('-');
    return countryCode?.toUpperCase();
  };

  const locationLanguage = useMemo(() => {
    return isPhoneNumber ? getLocationLanguage(navigator?.language) : null;
  }, [isPhoneNumber, navigator?.language]);

  useEffect(() => {
    if (isPhoneNumber && value) {
      onChange(name, new AsYouType().input(value));
    }
  }, []);

  function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  }

  const handleCopyText = event => {
    event.preventDefault();

    if (isCopied) return null;

    return copyTextToClipboard(value).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 4000);
    });
  };

  const handlePhoneNumber = number => new AsYouType().input(number);

  const handleChange = useCallback(
    event => {
      event.preventDefault();
      const { name: inputName, value: number } = event.target;
      const typedValue = isPhoneNumber ? handlePhoneNumber(number) : number;

      debounce(() => onChange(inputName, typedValue));
    },
    [isPhoneNumber, onChange],
  );

  const handleClearField = useCallback(
    event => {
      event.preventDefault();
      debounce(() => onChange(name, ''));
    },
    [name, onChange],
  );

  const handleApplyDialCode = () => {
    if (!isPhoneNumber) return null;

    if (!value) {
      setInputValue(byCountryCode[locationLanguage]?.dialCode || '+55');
    }

    return !value.startsWith('+')
      ? onChange(
          name,
          `${byCountryCode[locationLanguage]?.dialCode || '+55'}${value}`,
        )
      : null;
  };

  return (
    <S.Wrapper error={error}>
      {!!label && (
        <S.LabelWrapper>
          <S.Label htmlFor={name}>{label}</S.Label>
          {props.required && '*'}
        </S.LabelWrapper>
      )}
      <S.InputGroup
        error={error}
        clipboardActive={clipboardOptions.active}
        primaryColor={primaryColor}
        size={size}
        variant={variant}
      >
        {Icon && (
          <S.InputIcon $size={size} error={error}>
            {Icon}
          </S.InputIcon>
        )}
        <S.Input
          aria-label={name}
          {...props}
          $size={size}
          value={value}
          onChange={!readOnly ? handleChange : null}
          name={name}
          id={name}
          readOnly={readOnly}
          onFocus={handleApplyDialCode}
        />
        {clearField && (
          <S.ClearFieldButton onClick={e => handleClearField(e)}>
            <S.ClearIcon $size={size} />
          </S.ClearFieldButton>
        )}
        {clipboardOptions.active && (
          <S.ClipboardButton onClick={handleCopyText}>
            {isCopied ? (
              <>
                <MdCheck size={16} color="black" />
                {clipboardOptions.copiedText}
              </>
            ) : (
              <>
                <MdContentCopy size={16} color="black" />
                {clipboardOptions.copyText}
              </>
            )}
          </S.ClipboardButton>
        )}
      </S.InputGroup>
      {error && typeof error === 'string' && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  );
};

export default CustomInput;

CustomInput.propTypes = {
  onChange: propTypes.func,
  name: propTypes.string.isRequired,
  value: propTypes.any,
  delay: propTypes.number,
  pattern: propTypes.oneOfType([
    propTypes.instanceOf(RegExp),
    propTypes.string,
  ]),
  label: propTypes.string,
  primaryColor: propTypes.string,
  size: propTypes.string,
  variant: propTypes.string,
  icon: propTypes.element,
  error: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  required: propTypes.bool,
  readOnly: propTypes.bool,
  isPhoneNumber: propTypes.bool,
  clearField: propTypes.bool,
  debounceDelay: propTypes.number,
  clipboardOptions: propTypes.shape({
    copyText: propTypes.string.isRequired,
    copiedText: propTypes.string.isRequired,
    active: propTypes.bool.isRequired,
  }),
};

CustomInput.defaultProps = {
  delay: 0,
  onChange: () => null,
  icon: <></>,
  value: '',
  pattern: '',
  primaryColor: 'primary500',
  size: 'normal',
  variant: 'normal',
  required: false,
  readOnly: false,
  isPhoneNumber: false,
  clearField: false,
  clipboardOptions: {
    copyText: '',
    copiedText: '',
    active: false,
  },
  error: '',
  label: '',
  debounceDelay: 0,
};
